import { ReactNode } from "react";
import OrganizationCell from "components/atoms/Cells/OrganizationCell";
import FileNameCell from "components/atoms/Cells/FileNameCell";
import ResourceCell from "components/atoms/Cells/ResourceCell";
import BooleanFilter from "components/molecules/TableFragments/BooleanFilter";

import { ConvertDateToUTC } from "./ReactHelpers";

import TextCell from "../components/atoms/Cells/TextCell";
import InputFilter from "../components/molecules/TableFragments/InputFilter";
import CategoryFilter from "../components/molecules/TableFragments/CategoryFilter";
import LocaleTextCell from "../components/atoms/Cells/LocaleTextCell";
import ImageCell from "../components/atoms/Cells/ImageCell";
import CountryCell from "../components/atoms/Cells/CountryCell";
import CountriesSelectFilter from "../components/molecules/TableFragments/CountriesSelectFilter";
import DateCell from "../components/atoms/Cells/DateCell";
import BooleanCell from "../components/atoms/Cells/BooleanCell";
import ArrayTextCell from "../components/atoms/Cells/ArrayTextCell";
import { IHomeDisplay, IFilters } from "../api";
import { ITableControlParams, IColumn } from "../interfaces/tables";
import ExpanderCell from "../components/atoms/Cells/ExpanderCell";
import NewsTitleCell from "../components/atoms/Cells/NewsTitleCell";
import HomepageCell from "../components/atoms/Cells/HomepageCell";
import NewsActionCell from "../components/atoms/Cells/NewsActionCell";
import RankCell from "../components/atoms/Cells/RankCell";
import { IDropDownOption } from "../interfaces";
import OrgCategoryFilter from "../components/molecules/TableFragments/OrgCategoryFilter";
import EntityCategoryFilter from "../components/molecules/TableFragments/EntityCategoryFilter";
import { ENTITY_CATEGORY_TITLE } from "../constants/entityCategories";
import { ORG_CATEGORY_TITLE } from "../constants/organizationCategories";
import EditActionCell from "../components/atoms/Cells/EditActionCell";
import AuthorCell from "../components/atoms/Cells/AuthorCell";

export enum definedAccessors {
  category = "category",
  entityCategory = "entityCategory",
  orgCategory = "orgCategory",
  blacklisted = "blacklisted",
  automaticPosting = "automaticPosting",
  email = "email",
  username = "username",
  author = "author",
  creator = "creator",
  creatorName = "creatorName",
  status = "status",
  type = "type",
  imageUrl = "imageUrl",
  picture = "picture",
  localeKeyword = "localeKeyword",
  localeCategory = "localeCategory",
  countries = "countries",
  createdAt = "createdAt",
  toBePostedAt = "toBePostedAt",
  updatedAt = "updatedAt",
  dateFrom = "dateFrom",
  dateTo = "dateTo",
  show = "show",
  active = "active",
  highlighted = "highlighted",
  displayInHomePage = "displayInHomePage",
  enabled = "enabled",
  partner = "partner",
  userGroups = "userGroups",
  expander = "expander",
  action = "action",
  displayName = "displayName",
  homepage = "homepage",
  title = "title",
  newsAction = "newsAction",
  editAction = "editAction",
  rank = "rank",
  homepageRank = "homepageRank",
  created = "created",
  role = "role",
  organizationId = "organizationId",
  imageAuthor = "imageAuthor",
  key = "key",
  resources = "resources",
}

const ACCESSOR_TITLES: Record<definedAccessors, string> = {
  imageUrl: "Attēls",
  blacklisted: "Blacklistē?",
  automaticPosting: "Saites Automātiksi publicējas?",
  picture: "Attēls",
  title: "Virsraksts",
  category: "Kategorija",
  entityCategory: "Kategorija",
  orgCategory: "Kategorija",
  organizationId: "Organizācija",
  email: "E-pasts",
  author: "Autors",
  creator: "Izveidoja",
  creatorName: "Ievietoja",
  username: "Lietotāja ID",
  status: "Statuss",
  type: "Tips",
  localeKeyword: "Atslēgvārds",
  localeCategory: "Kategorija",
  countries: "Valsts",
  createdAt: "Izveidots",
  toBePostedAt: "Publicēts",
  updatedAt: "Atjaunots",
  dateFrom: "Sākums",
  dateTo: "Beigas",
  show: "Portālā?",
  highlighted: "Izcelts?",
  displayInHomePage: "Sākumlapā?",
  enabled: "Aktīvs",
  partner: "Partneris?",
  userGroups: "Organizācijas un Uzņēmumi",
  displayName: "Vārds",
  newsAction: "Darbības",
  editAction: "Darbības",
  action: "Darbības",
  homepage: "Mājaslapa",
  created: "Piereģistrējies",
  expander: "",
  rank: "Sākumlapā?",
  homepageRank: "Sākumlapā?",
  role: "Loma",
  imageAuthor: "Avots (attēla birka)",
  key: "Fails",
  resources: "Izmantota Resursos",
  active: "Aktīva?",
};

export const booleanAccessors = [
  definedAccessors.active,
  definedAccessors.show,
  definedAccessors.highlighted,
  definedAccessors.enabled,
  definedAccessors.partner,
  definedAccessors.displayInHomePage,
  definedAccessors.blacklisted,
  definedAccessors.automaticPosting,
];

export function columnMaker<T extends object>(
  accessors: definedAccessors[],
  actionColumn?: Partial<IColumn<T>>,
  opts?: { disableFilters?: boolean },
) {
  const textAccessors = [
    definedAccessors.category,
    definedAccessors.entityCategory,
    definedAccessors.orgCategory,
    definedAccessors.email,
    definedAccessors.username,
    definedAccessors.status,
    definedAccessors.type,
    definedAccessors.creator,
    definedAccessors.displayName,
    definedAccessors.creatorName,
    definedAccessors.imageAuthor,
  ];
  const imageAccessors = [definedAccessors.imageUrl, definedAccessors.picture];
  const localeTextAccessors = [
    definedAccessors.localeKeyword,
    definedAccessors.localeCategory,
    definedAccessors.role,
  ];
  const countryAccessors = [definedAccessors.countries];
  const dateAccessors = [
    definedAccessors.createdAt,
    definedAccessors.toBePostedAt,
    definedAccessors.updatedAt,
    definedAccessors.dateFrom,
    definedAccessors.dateTo,
    definedAccessors.created,
  ];

  const arrayAccessors = [definedAccessors.userGroups];

  return accessors.map((acc) => {
    let disableSortBy = true;
    let disableFilters = true;
    let Filter = InputFilter;
    let sortDescFirst = false;
    let cellRenderer: ReactNode;

    if (textAccessors.includes(acc)) {
      disableFilters = false;
      cellRenderer = TextCell;
      if (acc === definedAccessors.category) {
        Filter = CategoryFilter;
      } else if (acc === definedAccessors.orgCategory) {
        Filter = OrgCategoryFilter;
        cellRenderer = ({ row }: any) =>
          ORG_CATEGORY_TITLE[
            row.original.category as keyof typeof ORG_CATEGORY_TITLE
          ] || "-";
      } else if (acc === definedAccessors.entityCategory) {
        Filter = EntityCategoryFilter;
        cellRenderer = ({ row }: any) =>
          ENTITY_CATEGORY_TITLE[
            row.original.category as keyof typeof ENTITY_CATEGORY_TITLE
          ] || "-";
      } else {
        Filter = InputFilter;
      }
      if (acc === definedAccessors.status) {
        cellRenderer = (cell: any) => {
          const {
            value,
            row: { original },
          } = cell;
          if (value === "CONFIRMED") {
            return "Apstiprināts lietotājs";
          }
          if (value === "EXTERNAL_PROVIDER") {
            if (original.username.includes("facebook")) {
              return "Facebook lietotājs";
            }
            if (original.username.includes("google")) {
              return "Google lietotājs";
            }
          }
          return `${value}`;
        };
        disableFilters = true;
      }
      if (acc === definedAccessors.type) {
        disableFilters = true;
      }
    } else if (localeTextAccessors.includes(acc)) {
      cellRenderer = LocaleTextCell;
      disableSortBy = false;
      Filter =
        acc === definedAccessors.localeCategory ? CategoryFilter : InputFilter;
    } else if (imageAccessors.includes(acc)) {
      cellRenderer = ImageCell;
    } else if (countryAccessors.includes(acc)) {
      disableFilters = false;
      cellRenderer = CountryCell;
      Filter = CountriesSelectFilter;
    } else if (dateAccessors.includes(acc)) {
      disableSortBy = false;
      sortDescFirst = true;
      cellRenderer = DateCell;
      if (
        acc === definedAccessors.dateFrom ||
        acc === definedAccessors.dateTo
      ) {
        cellRenderer = (cell: any) => {
          const date = cell.value;
          return `${ConvertDateToUTC(date, "DD. MMM. YYYY HH:mm")} (GMT)`;
        };
      }
    } else if (booleanAccessors.includes(acc)) {
      cellRenderer = BooleanCell;
      disableFilters = acc !== definedAccessors.partner;
      Filter = BooleanFilter;
    } else if (arrayAccessors.includes(acc)) {
      cellRenderer = ArrayTextCell;
      // SPECIAL ACCESSORS BELOW
    } else if (acc === definedAccessors.resources) {
      cellRenderer = ResourceCell;
    } else if (acc === definedAccessors.rank) {
      cellRenderer = RankCell;
    } else if (acc === definedAccessors.homepageRank) {
      cellRenderer = RankCell;
      disableSortBy = false;
    } else if (acc === definedAccessors.expander) {
      cellRenderer = ExpanderCell;
    } else if (acc === definedAccessors.organizationId) {
      cellRenderer = OrganizationCell;
    } else if (acc === definedAccessors.homepage) {
      cellRenderer = HomepageCell;
    } else if (acc === definedAccessors.title) {
      disableFilters = false;
      cellRenderer = NewsTitleCell;
    } else if (acc === definedAccessors.author) {
      disableFilters = false;
      cellRenderer = AuthorCell;
    } else if (acc === definedAccessors.newsAction) {
      cellRenderer = NewsActionCell;
    } else if (acc === definedAccessors.key) {
      cellRenderer = FileNameCell;
    } else if (acc === definedAccessors.editAction) {
      cellRenderer = EditActionCell;
    } else if (acc === definedAccessors.action && actionColumn) {
      return {
        Header: ACCESSOR_TITLES[acc],
        accessor: acc,
        disableSortBy,
        disableFilters,
        ...actionColumn,
      };
    }

    return {
      Header: ACCESSOR_TITLES[acc],
      accessor: acc,
      Cell: cellRenderer,
      Filter,
      disableSortBy,
      disableFilters: opts?.disableFilters ? true : disableFilters,
      sortDescFirst,
    };
  });
}

// DO not like this function. doubled parsing :(
export function tableToQueryFilters(
  tableFilters: ITableControlParams<IHomeDisplay>,
): IFilters {
  const { pageSize, pageIndex, filters, sortBy } = tableFilters;
  const queryFilters: IFilters = {
    limit: pageSize,
    offset: pageIndex * pageSize,
  };
  const country = filters.find((f) => f.id === "countries")?.value || [];
  const keyword = filters.find((f) => f.id === "category")?.value || [];
  const title = filters.find((f) => f.id === "title")?.value || "";
  const partner = filters.find((f) => f.id === "partner")?.value || undefined;
  console.log("partner", partner);
  if (country.length) queryFilters.countries = country;
  if (keyword.length) queryFilters.keywords = keyword;
  if (title) queryFilters.title = title;
  if (partner === "true") queryFilters.partner = "true";
  if (partner === "false") queryFilters.partner = "false";
  queryFilters.sort = sortBy
    .map((term) => {
      if (term.desc === undefined) return undefined;
      return `${term.id}${term.desc ? ":desc" : ""}`;
    })
    .filter((term) => term)
    .join(",");

  console.log("queryFilters", queryFilters);
  return queryFilters;
}

export const FormatDrpValToOption = (values: string[]) => {
  const options: IDropDownOption[] = values.map(
    (v: string) => ({ text: v, key: v, value: v } as IDropDownOption),
  );
  return options;
};
