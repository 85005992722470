import * as React from "react";
import { Card, Loader, Image, Input, Button } from "semantic-ui-react";
import { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import slugify from "slugify";
import { convertToRaw, ContentState, RawDraftContentState } from "draft-js";
import FormRichTextArea from "components/molecules/FormFields/FormRichTextArea";
import useAuthorizedContext from "hooks/useAuthorizedContext";

import { getStaticData, updateStaticData } from "../../../api/staticPageApi";
import { PLACEHOLDER_SQUARE } from "../../../images/imageSources";
import SimpleButton, {
  definedButtonTypes,
} from "../../atoms/Buttons/SimpleButton/SimpleButton";
import { deleteData } from "../../../api/DynamicApi";
import { IStaticInfo } from "../../../api";
import { definedFooterRoutes } from "../../../api/api";

export interface ContactsPanelProps {
  type: definedFooterRoutes;
}
const MarginSetter = styled.div`
 &&& {
   margin-top: 20px; 
 },
`;

const StyledHeader = styled(Card.Header)`
  &&& {
    margin-bottom: 0.4em;
  }
`;

const StyledCard = styled(Card)`
  &&& {
    &:hover {
      transform: translateY(-3px);
      box-shadow: grey;
    }
  }
`;

const ContactsPanel: React.FC<ContactsPanelProps> = ({ type }) => {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState<IStaticInfo[]>([]);
  const { t } = useTranslation();
  const { token } = useAuthorizedContext();
  React.useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    getStaticData(type)
      .then((data) => {
        if (signal.aborted) return;
        setContacts(data);
        setLoading(false);
      })
      .catch((error: Error) => {
        console.error("error: ", error);
      });

    return () => {
      abortController.abort();
    };
  }, [type]);

  function addCard() {
    const newContactCard: IStaticInfo = {
      type,
      order: "",
      address: undefined,
      highlighted: false,
      show: true,
      displayInHomePage: false,
      slug: "",
      title: "",
      email: [],
      phone: [],
      description: convertToRaw(ContentState.createFromText("")),
      imageUrl: "",
    };
    const newContacts: IStaticInfo[] = [...contacts, newContactCard];
    setContacts(newContacts);
  }

  async function saveCard(index: number) {
    const contact = contacts[index];
    contact.slug = slugify(contact.title, { lower: true });
    const idToken = await token();
    updateStaticData(idToken, type, contact)
      .then(() => {
        toast.success(t("Footer.Success.Update"));
        setContacts(contacts);
      })
      .catch((e) => {
        toast.error(t("Footer.Error.Update"));
      });
  }

  async function removeCard(index: number) {
    const confirmation = window.confirm(t("Footer.ContactCard.Confirm"));
    if (confirmation) {
      const newContacts = contacts.filter((card, idx) => idx !== index);
      const toRemove = contacts[index];
      setLoading(true);
      const idToken = await token();
      deleteData(idToken, type, toRemove.slug, toRemove.imageUrl)
        .then(() => {
          toast.success(t("Footer.Success.Update"));
          setContacts(newContacts);
          setLoading(false);
        })
        .catch(() => {
          toast.error(t("Footer.Error.Update"));
        });
    }
  }
  function setDescription(
    index: number,
    id: string,
    value: RawDraftContentState,
  ) {
    const contact: IStaticInfo = contacts[index];
    contact.description = value;
    const newContacts: IStaticInfo[] = [...contacts];
    setContacts(newContacts);
  }

  function setData(index: number, id: string, value: string) {
    const contact: IStaticInfo = contacts[index];
    if (id === "name") contact.title = value;
    else if (id === "email" && contact.email) contact.email[0] = value;
    else if (id === "phone" && contact.phone) contact.phone[0] = value;
    else if (id === "image") contact.imageUrl = value;
    else if (id === "order") contact.order = value;
    const newContacts: IStaticInfo[] = [...contacts];
    setContacts(newContacts);
  }

  if (loading) return <Loader active inline="centered" />;

  return (
    <MarginSetter>
      <Card.Group>
        {contacts.map((card, idx) => (
          <StyledCard key={card.slug}>
            <Image
              src={card.imageUrl || PLACEHOLDER_SQUARE}
              wrapped
              ui={false}
              alt={card.title}
            />
            <Input
              value={card.imageUrl}
              id={`image-${idx}`}
              fluid
              onChange={(e) => setData(idx, "image", e.target.value)}
            />
            <Card.Content>
              <StyledHeader>
                <Input
                  fluid
                  placeholder={t("Forms.Contact.Name")}
                  value={card.title}
                  id={`name-${idx}`}
                  onChange={(e) => setData(idx, "name", e.target.value)}
                />
                <Input
                  fluid
                  label="Secība:"
                  value={card.order}
                  id={`order-${idx}`}
                  onChange={(e) => setData(idx, "order", e.target.value)}
                />
              </StyledHeader>
              <Card.Meta>
                <Input
                  placeholder={t("Forms.Contact.Email")}
                  value={(card.email && card.email[0]) || ""}
                  id={`email-${idx}`}
                  fluid
                  onChange={(e) => setData(idx, "email", e.target.value)}
                />
                <Input
                  placeholder={t("Forms.Contact.Phone")}
                  value={(card.phone && card.phone[0]) || ""}
                  id={`phone-${idx}`}
                  fluid
                  onChange={(e) => setData(idx, "phone", e.target.value)}
                />
              </Card.Meta>
              <Card.Description>
                <FormRichTextArea
                  id="cardDescription"
                  value={card.description}
                  onChange={(id, val) =>
                    setDescription(idx, "description", val)
                  }
                />
              </Card.Description>
            </Card.Content>
            <Card.Content>
              <Button.Group widths="2">
                <SimpleButton
                  variant={definedButtonTypes.confirm}
                  onClick={() => saveCard(idx)}
                >
                  SAGLABĀT
                </SimpleButton>
                <SimpleButton
                  variant={definedButtonTypes.cancel}
                  onClick={() => removeCard(idx)}
                >
                  NOŅEMT
                </SimpleButton>
              </Button.Group>
            </Card.Content>
          </StyledCard>
        ))}
        <StyledCard>
          <Image src={PLACEHOLDER_SQUARE} wrapped ui={false} alt="add" />
          <Card.Content>
            <SimpleButton
              variant={definedButtonTypes.confirm}
              onClick={() => addCard()}
            >
              PIEVIENOT
            </SimpleButton>
          </Card.Content>
        </StyledCard>
      </Card.Group>
    </MarginSetter>
  );
};

export default ContactsPanel;
