import { styledWithTheme } from "./theme";

const StyledBanner = styledWithTheme.div(({ theme }) => ({
  display: "flex",
  padding: "1em",
  background: theme.palette.mono.lightest,
  justifyContent: "center",
  flexDirection: "column",
  border: "none",
  boxShadow: "none",
  borderRadius: 0,
  alignItems: "center",
  "> *": {
    margin: "0.625em 0 !important",
  },
  flex: 1,
  ".h2": {
    marginTop: "0!important",
  },
  "#bannerText": {
    maxWidth: "40em",
  },
}));

export default StyledBanner;
