import { Moment } from "moment";
import { RawDraftContentState } from "draft-js";

import { definedRouteTypes, ContentStatusTypes } from "./api";

import { OrganizationCategories } from "../interfaces/organizationCategories";
import { definedAuthorTypes } from "../components/molecules/FormFields/FormAuthorFields";
import { ICardInfo } from "../components/staticPages/Contacts";
import { definedMedia } from "../components/atoms/Buttons/MediaButton";
import { COUNTRY } from "../constants/country";
import { CATEGORIES } from "../constants/categories";
import { ILocation, ISource } from "../interfaces";
import ITemplateSite from "../interfaces/templateSite/site";
import { ENTITY_CATEGORY } from "../constants/entityCategories";
import { REGION } from "../constants/regions";

type definedRouteKeys = keyof typeof definedRouteTypes;

/**
 * *********** ORGANIZATION ***********
 */
export interface IBaseEntityForm {
  id?: string;
  createdAt?: Date;
  title: string;
  shortTitle?: string;
  slug: string;
  shortSlug?: string;
  imagePointer?: File;
  imageUrl: string;
  imageThumbnailUrl?: string;
  description: RawDraftContentState;
  phone: string[];
  email: string[];
  website?: string;
  countries: COUNTRY[];
  address?: ILocation | null;
  media?: Partial<Record<definedMedia, string>>;
  bankCredentials?: string;
  show: boolean;
  highlighted: boolean;
  highlightedInCountry: boolean;
  templateSite?: ITemplateSite | null;
  creator?: string;
  seoDescription?: string;
  hasTemplateSite?: boolean;
  displayInHomePage: boolean; // default False
  creatorId?: string;
  url?: string;
  subCategories?: string[];
  contactPerson?: string;
  comment?: string;
}
export interface IOrganizationForm extends IBaseEntityForm {
  type: definedRouteTypes.organizations;
  category?: ORG_CATEGORY;
  organizationId: string;
  diasporeMedia: boolean;
  partner: boolean;
}
export interface ICompanyForm extends IBaseEntityForm {
  type: definedRouteTypes.companies;
  category?: ENTITY_CATEGORY;
}
export interface ISelfEmployedForm extends IBaseEntityForm {
  type: definedRouteTypes.selfEmployed;
  category?: ENTITY_CATEGORY;
  name: string;
  surname: string;
}

export type EntityForm = IOrganizationForm | ICompanyForm | ISelfEmployedForm;

export const isCompany = (entity?: EntityForm): entity is ICompanyForm => {
  return (entity as ICompanyForm)?.type === definedRouteTypes.companies;
};
export const isSelfEmployed = (
  entity?: EntityForm,
): entity is ISelfEmployedForm => {
  return (entity as ISelfEmployedForm)?.type === definedRouteTypes.selfEmployed;
};
export const isOrganization = (
  entity?: EntityForm,
): entity is IOrganizationForm => {
  return (
    (entity as IOrganizationForm)?.type === definedRouteTypes.organizations
  );
};

export interface IOrganizationDisplay extends IOrganizationForm {
  admins: string[]; // ADMIN PANEL ONLY PROP
}

export interface IOrganizationInfo {
  id: string;
  slug?: string;
  imageUrl?: string;
  title?: string;
  shortTitle?: string;
  diasporeMedia?: boolean;
}

/**
 * *********** USER ***********
 */

export enum UserStatus {
  suspended = "Suspended",
  inActive = "Inactive",
  active = "Active",
}

export interface IUserForm {
  id?: string;
  createdAt?: Date;
  username: string;
  name?: string;
  family_name?: string;
  imagePointer?: File;
  picture?: string;
  birthday?: string;
  aboutMe?: string;
  phone?: string[];
  email: string;
  media?: Partial<Record<definedMedia, string>>;
  status: UserStatus;
  enabled?: boolean;
  userGroups?: string[];
}
export interface IUserDisplay extends IUserForm {
  userGroups: string[];
}

export interface IPictureData {
  PK: string;
  SK: string;
  article: string[];
  city: string[];
  country: string[];
  organization: string[];
  tags: string[];
  name: string;
  path: string;
  searchName: string;
}
/**
 * *********** STATIC PAGES ***********
 */

export interface IStaticPageData {
  description?: RawDraftContentState;
  contacts?: ICardInfo[];
}
/**
 * *********** CONTENT ***********
 */

// FORMS
export interface IBaseForm<T = definedRouteTypes> {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  slug: string;
  title: string;
  imagePointer?: File;
  imageUrl?: string;
  imageAuthor?: string;
  description?: RawDraftContentState;
  category?: CATEGORIES;
  subCategories?: string[];
  countries?: COUNTRY[];
  address?: ILocation | null;
  toBePostedAt?: Moment;
  source?: ISource;
  authorType?: definedAuthorTypes;
  author?: string;
  creator?: string;
  creatorId?: string;
  creatorName?: string;
  organizationId?: string;
  organizationInfo?: IOrganizationInfo; // create interface
  anotherAuthor?: string;
  highlighted: boolean;
  show: boolean;
  highlightedInCountry?: boolean;
  exclusive?: boolean;
  customLabel?: string;
  displayInHomePage?: boolean;
  type: T;
  online?: boolean;
  seoDescription?: string;
  status?: ContentStatusTypes;
  homepageRank?: 1 | 2 | 3 | 4;
  region?: REGION;
}

export interface IEditorialNewsForm
  extends IBaseForm<definedRouteTypes.editorialNews> {
  region?: REGION;
  type: definedRouteTypes.editorialNews;
}

export interface IOffersForm extends IBaseForm<definedRouteTypes.offers> {
  region?: REGION;
  type: definedRouteTypes.offers;
}
export interface IDiasporeNewsForm
  extends IBaseForm<definedRouteTypes.diasporeNews> {
  region?: REGION;
  type: definedRouteTypes.diasporeNews;
}
export interface IOrganizationNewsForm
  extends IBaseForm<definedRouteTypes.organizationNews> {
  region?: REGION;
  type: definedRouteTypes.organizationNews;
}
export interface ICompanyNewsForm
  extends IBaseForm<definedRouteTypes.organizationNews> {
  region?: REGION;
  type: definedRouteTypes.companyNews;
}

export interface IEventsForm
  extends IBaseForm<definedRouteTypes.editorialNews> {
  dateFrom?: Moment;
  dateTo?: Moment;
  type: definedRouteTypes.events | definedRouteTypes.pastEvents;
}

export interface IStaticInfo extends IBaseForm<definedFooterRoutes> {
  email?: string[];
  phone?: string[];
  order?: string;
  type: definedFooterRoutes;
}

export interface IGalleryImage {
  imagePointer?: File;
  imageUrl?: string; // Implement thumbnails?
}
export interface IGalleryNewsForm
  extends IBaseForm<definedRouteTypes.imageGallery> {
  type: definedRouteTypes.imageGallery;
  imageLinks: Array<IGalleryImage>;
}
export interface IVideoNewsForm extends IBaseForm<definedRouteTypes.videoNews> {
  mediaLink: string;
  region?: REGION;
  type: definedRouteTypes.videoNews;
}

export interface IMediaLinkForm
  extends IBaseForm<definedRouteTypes.LINK_MEDIA> {
  type: definedRouteTypes.LINK_MEDIA;
  url: string;
  mediaSource: string;
  mediaSourceId: string;
}
export interface IMediaSourceForm
  extends IBaseForm<definedRouteTypes.SOURCE_MEDIA> {
  type: definedRouteTypes.SOURCE_MEDIA;
  url: string;
  blacklisted: boolean;
  automaticPosting: boolean;
}

// Display values
export interface IBaseDisplay<T = definedRouteTypes> extends IBaseForm<T> {
  id: string;
  createdAt: Date;
  updatedAt?: Date;
  title: string;
  imageUrl: string;
  imageAuthor: string;
  description: RawDraftContentState;
  category: CATEGORY;
  subCategories: string[];
  location?: COUNTRY;
  locationInfo?: ILocation | null;
  toBePostedAt?: Moment;
  dateFrom?: Moment;
  dateTo?: Moment;
  author: string;
  videoLink?: string;
  organizationInfo?: IOrganizationInfo;
  show: boolean;
  highlighted: boolean;
  creator: string;
}

export interface IHomeDisplay extends IBaseForm {
  dateFrom?: Moment;
  dateTo?: Moment;
  type: definedRouteTypes;
}

export interface IEditorialNewsDisplay
  extends IBaseDisplay,
  IEditorialNewsForm {
  type: definedRouteTypes.editorialNews;
}
export interface IDiasporeNewsDisplay extends IBaseDisplay, IDiasporeNewsForm {
  type: definedRouteTypes.diasporeNews;
}
export interface IOrganizationNewsDisplay
  extends IBaseDisplay,
  IOrganizationNewsForm {
  type: definedRouteTypes.organizationNews;
}

export interface IGalleryNewsDisplay extends IBaseDisplay, IGalleryNewsForm {
  type: definedRouteTypes.imageGallery;
}
export interface IVideoNewsDisplay extends IBaseDisplay, IVideoNewsForm {
  type: definedRouteTypes.videoNews;
}
export interface IOffersDisplay extends IBaseDisplay, IOffersForm {
  type: definedRouteTypes.offers;
}
export interface IEventsDisplay extends IBaseDisplay, IEventsForm {
  type: definedRouteTypes.events;
}

export interface IFilters {
  limit: number;
  offset: number;
  title?: string;
  keywords?: string;
  slug?: string;
  fromDate?: string;
  toDate?: string;
  country?: COUNTRY[];
  countries?: COUNTRY[];
  category?: CATEGORIES[] | OrganizationCategories[];
  organizationId?: string[];
  sort?: any;
  lite?: boolean;
  type?: string | definedRouteTypes[];
  lng?: string;
  highlighted?: "true" | "false";
  highlightedInCountry?: "true" | "false";
  displayInHomePage?: "true" | "false";
  showAllRecords?: "true" | "false";
  partner?: "true" | "false";
  show?: 1 | 0;
  homepageRank?: Array<"1" | "2" | "3" | "4">;
}
